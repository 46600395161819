<template>
	<div class="h-full flex flex-col">
		<TopBar :showHome="true" :showChat="true"/>
		<ProgressBar :current="2"/>
		<AppTitle :title="tableName" v-if="table"/>
		<div class="pt-2 text-gray-400 overflow-y-scroll flex-grow">
			<p>Select what types of food you prefer.</p>
			<div class="mt-6 relative">
				<div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
					<font-awesome-icon icon="fa-solid fa-magnifying-glass" class="pl-2 text-gray-400"/>
				</div>
				<input type="text" v-model="keywords" class="block w-full pl-12 py-5 pr-4 border border-gray-100 placeholder-gray-400 text-gray-500 focus:ring-red-500 focus:border-red-500" :class="{
					'rounded-3xl': !active_search,
					'rounded-t-3xl': active_search
				}" placeholder="Search food types..." @input="debounceSearch">
				<div class="bg-white rounded-b-3xl absolute w-full" v-show="active_search">
					<div 
						class="flex bg-white border border-gray-100 border-t-0 py-4 px-4 items-center text-gray-600"
						:class='index+1===filteredOptions.length ? "rounded-b-3xl" : ""'
						v-for="(row, index) in filteredOptions" 
						:key="row.uuid"
						@click.stop.prevent="inviteToTable(row)"
					>
						<p class="font-bold flex-1 ml-3">{{ row.label }}</p>
						<button class="p-1 border border-green-500 rounded-full cursor-pointer" @click.stop.prevent="selectCuisine(row, 1)">
							<font-awesome-icon icon="fa-solid fa-check" class="px-1 text-green-500"/>
						</button>
						<button class="p-1 border border-red-500 rounded-full ml-4 cursor-pointer" @click.stop.prevent="selectCuisine(row, -1)">
							<font-awesome-icon icon="fa-solid fa-close" class="px-1.5 text-red-500"/>
						</button>
					</div>
				</div>
			</div>
			<div class="py-8" v-if="cuisines">

				<div v-show="liked.length">
					<p class="text-gray-600 font-bold text-lg">
						<font-awesome-icon icon="fa-solid fa-check" class="pl-2 text-green-400 mr-1"/> Like
					</p>
					<div>
						<div class="flex text-gray-600 bg-white rounded-xl border border-gray-100 py-3 px-4 items-center mt-2" v-for="cuisine in liked" :key="cuisine.name">
							<p class="flex-1">{{ cuisine.label }}</p>
							<button class="px-2" @click.stop.prevent="removeCuisine(cuisine)">
								<font-awesome-icon icon="fa-solid fa-xmark"/>
							</button>
						</div>
					</div>
				</div>

				<div class="mt-6" v-show="disliked.length">
					<p class="text-gray-600 font-bold text-lg">
						<font-awesome-icon icon="fa-solid fa-xmark" class="pl-2 text-red-500 mr-1"/> Dislike
					</p>
					<div>
						<div class="flex text-gray-600 bg-white rounded-xl border border-gray-100 py-3 px-4 items-center mt-2" v-for="cuisine in disliked" :key="cuisine.name">
							<p class="flex-1">{{ cuisine.label }}</p>
							<button class="px-2" @click.stop.prevent="removeCuisine(cuisine)">
								<font-awesome-icon icon="fa-solid fa-xmark"/>
							</button>
						</div>
					</div>
				</div>

			</div>

		</div>

		<div class="w-full pt-10 pb-6 bg-gray-50">
			<div>
				<button class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg" @click="next">
					Next
				</button>
				<button class="text-gray-500 py-2 rounded-xl w-full font-gothic text-lg mt-4" @click="previous">
					Back
				</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import ProgressBar from '@/components/ProgressBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
	export default {
		components: {
			TopBar,
			AppTitle,
			ProgressBar,
		},
		data(){
			return {
				keywords: '',
				active_search: false,
				typing: null,
				debounce: null,
			}
		},
		watch: {
			keywords(value){
				if (value===''){
					this.active_search = false
				}
			}
		},
		computed: {
			tableName(){
				return this.table.name.length ? this.table.name : `Table ${this.table.code.toUpperCase()}`
			},
			table(){
				return this.$store.state.table.table;
			},
			cuisines: {
				get(){
					return this.$store.state.table.table.answers.cuisines;
				},
				set(value){
					this.$store.commit('table/SET_ANSWER', {
						name: 'cuisines',
						value: value
					});
				}
			},
			liked(){
				return this.cuisines.filter( x => x.score > 0 );
			},
			disliked(){
				return this.cuisines.filter( x => x.score < 0 );
			},
			options(){
				return this.$store.state.table.cuisines;
			},
			filteredOptions(){
				return this.$store.state.table.cuisines.filter( x => x.name.toLowerCase().indexOf(this.keywords.toLowerCase()) >= 0 ).slice(0,6);
			}
		},
		methods: {
			selectCuisine(cuisine, score){
				if (!this.cuisines){
					this.cuisines = [];
				}
				if (!this.cuisines.includes(cuisine)){
					this.cuisines.push({
						name: cuisine.name,
						label: cuisine.label,
						score: score
					});
					this.keywords = "";
					this.active_search = false;
				}
			},
			removeCuisine(cuisine){
				this.cuisines = this.cuisines.filter( x => x.name !== cuisine.name );
			},
			debounceSearch(event) {
				this.typing = true
				clearTimeout(this.debounce)
				this.debounce = setTimeout(() => {
					this.typing = false
					this.keywords = event.target.value
					if (this.keywords.length && this.filteredOptions.length){
						this.active_search = true;
					}
				}, 300)
			},
			next(){
				this.$store.dispatch('table/saveAnswers', {
					step: 3
				});
			},
			previous(){
				this.$store.dispatch('table/saveAnswers', {
					step: 1
				});
			},
			inviteToTable(user){
				this.$store.dispatch('table/inviteToTable', {
					user: user.uuid,
					table: this.$store.state.table.table.uuid
				}).then( () => {
					this.keywords = "";
				});
			},
		}
	}
</script>